<script>
import GlobalVue from '@helper/Global.vue';
import BaseVue from '@frontend/Base.vue';
import Gen from '@helper/Gen';


export default {
    extends: GlobalVue,
    data() {
        return {
          data:{},
          category:{}
        }
    },
    watch: {
        $route() {
            this.getListNews()
            this.refreshMeta()
        }
    },
    async mounted() {
        this.$set(this.$root, 'page', this)
        this.getListNews()
        this.refreshMeta()
    },
    methods: {
      getListNews(){
        Gen.apirest("/health-info-cat/"+this.$route.params.cat, Gen.clone(this.$route.query),(err,resp)=>{
          if(err) console.log("health info goodness error "+err)
          this.data = resp.data
          this.category = resp.category
        })
      },
      changePage(page=1){
        this.$router.push({
          query:Object.assign(Gen.clone(this.$route.query),{page:page,t: new Date().getTime()})
        })
      },
      refreshMeta(){
        Gen.rest(this.$route.path, {metainfo:true},(err,resp)=>{
          if(err) return console.error(err)
          $("title").text(resp.meta_title)
          $("meta[property='og:image']").attr('content', resp.ss_img)
          $("meta[property='og:title']").attr('content', resp.meta_title)
          $("meta[name='description']").attr('content', resp.ss_meta_desc)
          $("meta[property='og:description']").attr('content', resp.ss_meta_desc)
          $("meta[name='keywords']").attr('content', resp.ss_meta_keyword)
          $("meta[name='author']").attr('content', resp.ss_author)
          $("meta[property='og:site_name']").attr('content', resp.ss_sitename)
          $("meta[property='url']").attr('content', resp.ss_url)
          $("link[rel='canonical']").attr('content', resp.ss_url)
          this.$root.meta = resp
        })
      },
    },
};
</script>

<template>
    <section id="content">
      <div class="content-wrap py-0">
        <section id="page-title" class="page-title-mini">
          <div class="container clearfix">
            <h1>{{category.aic_name}}</h1>
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><router-link :to="{name:'Index'}">{{web.menu_home}}</router-link></li>
              <li class="breadcrumb-item"><router-link :to="{name:'HealthInfo'}">{{web.menu_health_info}}</router-link></li>
              <li class="breadcrumb-item active">{{category.aic_name}}</li>
            </ol>
          </div>
        </section>
        <section class="section bg_blue_general pb-0">
          <div class="container">
            <div class="health-info" v-if="data.data.length">
              <div class="row">
                <div class="col-lg-12" >
                  <router-link :to="{name:'HealthInfoDetail',params:{cat:v.aic_slug,slug:v.an_slug}}"  class="article" v-for="(v,k) in data.data" :key="k" :style="k%2==1?'--articlebg:#E7F175;':'--articlebg:#dee9f9;'">
                    <div class="row">
                      <div class="col-lg-6 px-0">
                        <div class="article__img">
                          <img :src="uploader(v.an_img)" :alt="v.an_title">
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="article-desc">
                          <h3 class="article-desc__title f_text_article" v-tooltip="v.an_title.length>78 ? v.an_title:''">{{(v.an_title.substr(0,78)+(v.an_title.length>78 ? '...':''))}}</h3>
                          <p>{{v.an_short_desc}}</p>
                          <router-link :to="{name:'HealthInfoDetail',params:{cat:v.aic_slug,slug:v.an_slug}}" class="cta cta-primary cta--reveal"><span>Read More</span><i class="icon-line-arrow-right"></i></router-link>
                        </div>
                      </div>
                    </div>
                  </router-link>
                </div>
              </div>

              <nav class="mt_pagination text-center">
                <Pagination :limit="2" :data="data" @pagination-change-page="changePage" class="pagination justify-content-center flex-wrap">
                  <i slot="prev-nav" class="icon-angle-left"></i>
                  <i slot="next-nav" class="icon-angle-right"></i>
                </Pagination>
              </nav>

            </div>
            <div class="notfound text-center" v-else>
              <img :src="assets('fo_images', 'search-no-result.png')" alt="" class="notfound__img">
              <h4 class="notfound__title">{{web.lbl_no_article}}</h4>
            </div>
          </div>
        </section>
      </div>
    </section>
</template>